import { isEmptyArray } from "../lib/arrayFunctions";
import { isValidCustomConferencing } from "../lib/conferencing";
import { safeJSONParse } from "../lib/jsonFunctions";
import { getCustomConferencingName, getCustomConferencingURL } from "../lib/settingsFunctions";
import { WHATSAPP_BASE_URL } from "../lib/stringFunctions";
import { getUserName } from "../lib/userFunctions";
import { OUTLOOK_CONFERENCING } from "../resources/outlookVariables";
import { getPhoneNumberString } from "./commonUsefulFunctions";
import { getEventConferenceURL, getEventExtendedProperties } from "./eventResourceAccessors";
import {
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_NEEDS_ACTION,
  BACKEND_CUSTOM_CONFERENCING,
  BACKEND_HANGOUT,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_ZOOM,
  GOOGLE_HANGOUT_STRING,
  NO_CONFERENCE_STRING,
  PHONE_NUMBER_CONFERENCE,
  WHATS_APP_STRING,
  ZOOM_STRING,
} from "./googleCalendarService";
import { getHoldDetailsID, getHoldDetailsLinkable, getHoldDetailsType } from "./maestro/maestroAccessors";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export const DEFAULT_HOLD_TITLE = "Hold";

/* holdDetails returns attendees as a JSON */
/* We parse the JSON in a try catch and return an array of attendees */
/* shouldOnlyParseJSON: true = just parse for names */
/* shouldOnlyParseJSON: false = add response status (for events) */
export function parseHoldEventAttendees({
  attendees,
  currentUser,
  masterAccount,
  shouldOnlyParseJSON = false,
}) {
  let parsedAttendees = [];
  parsedAttendees = safeJSONParse(attendees) ?? [];

  if (shouldOnlyParseJSON) {
    return parsedAttendees;
  }

  let eventAttendees = parsedAttendees.map((a) => ({ email: a.email, displayName: a.name, responseStatus: ATTENDEE_EVENT_NEEDS_ACTION }));
  /* Add self as organizer if not already present */
  if (!isEmptyArray(eventAttendees) && !eventAttendees.find((attendee) => attendee.email === currentUser.email)) {
    eventAttendees.push({
      email: currentUser.email,
      displayName: getUserName({ user: currentUser, masterAccount })
        .fullName,
      responseStatus: ATTENDEE_EVENT_ATTENDING,
    });
  }

  return eventAttendees;
}

export function getConferenceOptionFromHold({ conference, currentUser, event }) {
  if (!currentUser?.settings) {
    return { conference: GOOGLE_HANGOUT_STRING, link: null };
  }

  const { default_phone_option } = currentUser.settings;

  switch (conference) {
    case BACKEND_ZOOM:
      return { conference: ZOOM_STRING, link: currentUser.zoom_link };
    case BACKEND_HANGOUT:
      return {
        conference: GOOGLE_HANGOUT_STRING,
        link: event ? getEventConferenceURL(event) : null,
      };
    case BACKEND_PHONE:
      if (default_phone_option === BACKEND_WHATS_APP) {
        return {
          conference: WHATS_APP_STRING,
          link: `${WHATSAPP_BASE_URL}${getPhoneNumberString(
            currentUser.phone_number,
            currentUser.phone_region_code,
          )}`,
        };
      }
      return { conference: PHONE_NUMBER_CONFERENCE, link: null };
    case BACKEND_CUSTOM_CONFERENCING:
      if (isValidCustomConferencing(currentUser)) {
        return {
          conference: getCustomConferencingName({ user: currentUser }),
          link: getCustomConferencingURL({ user: currentUser }),
        };
      } else {
        return { conference: NO_CONFERENCE_STRING, link: null };
      }
    case OUTLOOK_CONFERENCING.teamsForBusiness:
      return { conference: OUTLOOK_CONFERENCING.teamsForBusiness };
    case OUTLOOK_CONFERENCING.skypeForBusiness:
      return { conference: OUTLOOK_CONFERENCING.skypeForBusiness };
    case OUTLOOK_CONFERENCING.skypeForConsumer:
      return { conference: OUTLOOK_CONFERENCING.skypeForConsumer };
    default:
      return { conference: NO_CONFERENCE_STRING, link: null };
  }
}

/* Get the hold_details from an event */
export function getEventHoldDetails(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  return event?.hold_details;
}

/* Get the vholds_id from an event */
/* This resides under hold_details */
export function getEventHoldID(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  return getHoldDetailsID({ holdDetails: getEventHoldDetails(event) }) || getEventExtendedProperties(event)?.private?.vholds_id;
}

export function getEventHoldLinkable(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return "";
  }

  return getHoldDetailsLinkable({ holdDetails: getEventHoldDetails(event) });
}

export function getEventHoldType(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  return getHoldDetailsType({ holdDetails: getEventHoldDetails(event) });
}

/* Check if an event is a hold event */
/* 1) Has a holds id */
/* 2) Has not been converted (edit form) */
export function isEventHoldEvent(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  return !!getEventHoldID(event);
}

export function getFrontendHoldEventsHoldsID(holdEvents) {
  if (isEmptyObjectOrFalsey(holdEvents) || !holdEvents?.vholds_id) {
    return "";
  }

  return holdEvents?.vholds_id;
}
