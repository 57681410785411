import {
  startOfMinute,
  differenceInDays,
  isSameMinute,
  startOfDay,
  endOfDay,
  subDays,
  getMinutes,
  addMinutes,
  differenceInMinutes,
  getHours,
} from "date-fns";
import overlap from "rbc-fork-react-big-calendar/lib/utils/layout-algorithms/overlap";
import { getEventDuration } from "./eventFunctions";
import { isValidJSDate } from "../services/commonUsefulFunctions";

export const RBC_VARIABLES = {
  ACTION_CLICK: "click",
  ACTION_SELECT: "select"
};

export function isSelectedSlotAllDayEvent(slot) {
  const { start, end } = slot;

  const eventStart = startOfMinute(start);
  const eventEnd = startOfMinute(end);
  return differenceInDays(eventEnd, eventStart) > 0;
}

export function isRBCAllDayEvent(e) {
  const {
    start,
    end,
  } = e;

  return getHours(start) === 0 && getMinutes(start) && getHours(end) === 0 && getMinutes(end) === 0;
}

export function isEventSlotAllDayEvent(slot) {
  const { eventStart, eventEnd } = slot;

  return (
    isSameMinute(eventStart, eventEnd) ||
    differenceInDays(startOfMinute(eventEnd), startOfMinute(eventStart)) > 0
  );
}

export function defaultSlotGetter({date, workHours}) {
  const hour = date.getHours();
  let shouldShade = hour < workHours.startWorkHour || hour >= workHours.endWorkHour;
  return { className: shouldShade ? "time-slot-time-zone-slot-shade" : "" };
}

export function defaultCustomDayLayout(params) {
  return overlap({ ...params, minimumStartDifference: 15 });
}

export function getRBCMinTime() {
  return startOfDay(new Date());
}

export function getRBCMaxTime() {
  return endOfDay(new Date());
}

export function protectMidnightCarryOver(jsDate) {
  // if event ends at midnight, it bleeds into next day
  if (jsDate.getHours() === 0 && jsDate.getMinutes() === 0) {
    return endOfDay(subDays(jsDate, 1));
  }

  return jsDate;
}

export function getDragStartAndEnd({ dragStart, dragEnd, event }) {
  if (!isValidJSDate(dragStart) || !isValidJSDate(dragEnd)) {
    return { calculatedDragStart: null, calculatedDragEnd: null };
  }

  const dragDuration = differenceInMinutes(dragEnd, dragStart);
  const eventDuration = getEventDuration(event);
  if (eventDuration !== dragDuration) {
    // resize, no need to change
    return {calculatedDragStart: dragStart, calculatedDragEnd: dragEnd};
  }

  const calculatedDragStart = getDragStart({ dragStart, event });

  return {
    calculatedDragStart: calculatedDragStart,
    calculatedDragEnd: addMinutes(calculatedDragStart, dragDuration),
  };
}

function getDragTime({ draggedTime, originalTime }) {
  const remainerMinute = getMinutes(originalTime) % 15;
  if (remainerMinute === 0) {
    return draggedTime;
  }

  return addMinutes(draggedTime, remainerMinute);
}

function getDragStart({ dragStart, event }) {
  const { eventStart } = event;
  return getDragTime({draggedTime: dragStart, originalTime: eventStart});
}

export function isInsideRBCSlotRange({jsDate, eventStart, eventEnd}) {
  // since this gets called for every slot, we want to be super careful with this and want to use as cheap of a function as possible
  return jsDate >= eventStart && jsDate < eventEnd;
}

export function isOnClickSlot(slot) {
  return slot?.action === RBC_VARIABLES.ACTION_CLICK;
}

export function isOnSelectSlot(slot) {
  return slot?.action === RBC_VARIABLES.ACTION_SELECT;
}
