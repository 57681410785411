import {
  OUTLOOK_ADDITIONAL_SCOPES,
  OUTLOOK_CONFERENCING,
  OUTLOOK_DEFAULT_SCOPES,
} from "../resources/outlookVariables";
import {
  getCalendarAllowedMeetingProviders,
  getCalendarDefaultMeetingProvider,
  getCalendarEmail,
  getCalendarObject,
} from "../services/calendarAccessors";
import {
  getEventAttendees,
  getEventCategories,
  getEventRawData,
  getOutlookResponseRequested,
  isEventOrganizer,
} from "../services/eventResourceAccessors";
import { isUserEmailOrganizer } from "../services/commonUsefulFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import {
  microsoftTeamsIconURL,
  SKYPE_FOR_BUSINESS_ICON,
  SKYPE_ICON_URL,
} from "../services/globalVariables";
import {
  getCalendarFromUserCalendarID,
  getUserPrimaryCalendar,
  isCalendarOutlookCalendar,
} from "./calendarFunctions";
import { getHomeLink } from "./envFunctions";
import {
  formatEventsArrayForReactBigCalendar,
  getEventCalendarProviderID,
  getUniquePreviewOutlookEvents,
  isOutlookEvent,
  isOutOfOfficeEvent,
} from "./eventFunctions";
import { getRerouteForAddAccountOnboarding } from "../services/queryParamFunctions";
import { isEmptyArray } from "./arrayFunctions";
import {
  getAllTagsFromEvent,
  isPriorityTag,
  isTransparentTag,
} from "./tagsFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { MAIN_CALENDAR_BROADCAST_VALUES } from "./broadcastValues";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { MAGIC_LINK_PATH } from "../services/maestro/maestroRouting";
import { isSyncingCategories } from "./featureFlagFunctions";

export const TEAMS_FOR_BUSINESS_LABEL = "Teams";
export const SKYPE_FOR_BUSINESS_LABEL = "Skype for Business";
export const SKYPE_FOR_CONSUMER_LABEL = "Skype for Consumer";

export const OUTLOOK_OOO_EVENT_COLOR = "#AC319D";

// any more than this and we start limit the window of the sync
export const OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT = 4;

// Backend Settings name for Outlook Conferencing
export const BACKEND_OUTLOOK_CONFERENCING = "outlook_default";

export const DEFAULT_OUTLOOK_CALENDAR_HEX = "#a6d1f5";

export function isHumanReadableOutlookConferencingOption(option) {
  return [
    TEAMS_FOR_BUSINESS_LABEL,
    SKYPE_FOR_CONSUMER_LABEL,
    SKYPE_FOR_BUSINESS_LABEL,
  ].includes(option);
}

export function convertOutlookConferencingToHumanReadable(conferencing) {
  switch (conferencing) {
    case OUTLOOK_CONFERENCING.teamsForBusiness:
      return TEAMS_FOR_BUSINESS_LABEL;
    case OUTLOOK_CONFERENCING.skypeForBusiness:
      return SKYPE_FOR_BUSINESS_LABEL;
    case OUTLOOK_CONFERENCING.skypeForConsumer:
      return SKYPE_FOR_CONSUMER_LABEL;
    default:
      return conferencing;
  }
}

export function getOutlookConferencingIconURL(conferencing) {
  if (OUTLOOK_CONFERENCING.teamsForBusiness === conferencing) {
    return microsoftTeamsIconURL;
  } else if (OUTLOOK_CONFERENCING.skypeForBusiness === conferencing) {
    return SKYPE_FOR_BUSINESS_ICON;
  } else if (OUTLOOK_CONFERENCING.skypeForConsumer === conferencing) {
    return SKYPE_ICON_URL;
  }

  return SKYPE_ICON_URL;
}

export function isTemplateOutlookConferencing(conferencing) {
  const { teamsForBusiness, skypeForBusiness, skypeForConsumer } =
    OUTLOOK_CONFERENCING;

  return [teamsForBusiness, skypeForBusiness, skypeForConsumer].includes(
    conferencing?.conferenceType
  );
}

export const PROVIDER_TYPES = {
  OUTLOOK: "outlook",
  GOOGLE: "google",
};
export function isOutlookUser(user) {
  return user?.provider === PROVIDER_TYPES.OUTLOOK;
}

export function isOutlookConferencingOption(conferencing) {
  return !!OUTLOOK_CONFERENCING[conferencing];
}

// Gets all the allowed_online_meeting_providers on user's primary calendar
// Maps them into a { label, value } object for select
export function determineOutlookAllowedConferencing(allCalendars, email) {
  const userPrimaryCalendar = getUserPrimaryCalendar({ allCalendars, email });
  const allowedConferencingOptions =
    getCalendarAllowedMeetingProviders(userPrimaryCalendar);

  // Check if we have the following:
  // allCalendars, primary calendar, allowed conferencing
  if (
    isEmptyObjectOrFalsey(allCalendars) ||
    !userPrimaryCalendar ||
    !allowedConferencingOptions
  ) {
    return [];
  }

  let formattedMeetingOptions = [];
  allowedConferencingOptions.forEach((option) => {
    if (OUTLOOK_CONFERENCING[option]) {
      formattedMeetingOptions = formattedMeetingOptions.concat({
        label: convertOutlookConferencingToHumanReadable(option),
        value: option,
      });
    }
  });

  return formattedMeetingOptions;
}

// Gets the defaultOnlineMeetingProvider on the user's primary calendar for Outlook user
// Should only be used for Outlook
export function getPrimaryCalendarConferencing(allCalendars, email) {
  const userPrimaryCalendar = getUserPrimaryCalendar({ allCalendars, email });
  const defaultConferencingOnCalendar =
    getCalendarDefaultMeetingProvider(userPrimaryCalendar);
  const allowedOnlineMeetingProviders =
    getCalendarAllowedMeetingProviders(userPrimaryCalendar);

  // Check if we have the following:
  // allCalendars, primary calendar, default conferencing, default conferencing allowed in backend
  // [Outlook] fallback to allowedOnlineMettingProviders if it exists and has value(s)
  if (
    (isEmptyObjectOrFalsey(allCalendars) ||
      !userPrimaryCalendar ||
      !defaultConferencingOnCalendar ||
      !isOutlookConferencingOption(defaultConferencingOnCalendar)) &&
    (!allowedOnlineMeetingProviders ||
      allowedOnlineMeetingProviders.length === 0)
  ) {
    return null;
  }

  // Check for unknown type as this isn't an accepted
  // Only really need to do this for Outlook
  return defaultConferencingOnCalendar !== "unknown"
    ? defaultConferencingOnCalendar
    : allowedOnlineMeetingProviders[0];
}

/**
 * @param {Object} options
 * @param {boolean=} options.isDesktopLogin
 * @param {boolean=} options.isInOnboarding
 * @param {string=} options.userEmail
 * @param {string=} options.accountType
 * @param {boolean=} options.showConsentScreen
 * @param {boolean=} options.isMagicLink
 */
export function getOutLookLoginURL({
  isDesktopLogin,
  isInOnboarding, // if it's in add onboarding, we update the redirect route
  userEmail,
  accountType,
  showConsentScreen,
  isMagicLink,
}) {
  const getRedirectURL = () => {
    if (isMagicLink) {
      return MAGIC_LINK_PATH;
    }

    if (isInOnboarding) {
      // user should only be abel to get to login page in onboarding for web
      return "welcome";
    }

    return isDesktopLogin ? "desktop-login" : "login";
  };
  const baseURL =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  const clientID = "client_id=6f69f169-45a2-4a4f-a12f-81c707de1f75";
  const redirectURL = `redirect_uri=${getHomeLink()}/${getRedirectURL()}`;
  const scopes = [...OUTLOOK_DEFAULT_SCOPES, ...OUTLOOK_ADDITIONAL_SCOPES].join(
    "%20"
  );

  /* Guide user to correct email to re auth with */
  const loginHint = userEmail ? `&login_hint=${userEmail}` : "";
  const isPromptConsent = showConsentScreen || !!loginHint;
  const promptType = isPromptConsent ? "consent" : "select_account";

  const ACCOUNT_TYPE_START = "account_type_start_"; // need this to match substring
  const ACCOUNT_TYPE_END = "_account_type_end"; // need this to match substring
  const OUTLOOK_LOGIN_STATE = "outlook_login";

  const getStateString = () => {
    let returnString = "";
    if (accountType) {
      returnString += `&state=${OUTLOOK_LOGIN_STATE}`;
      returnString += `_${ACCOUNT_TYPE_START}${accountType}${ACCOUNT_TYPE_END}_`;
    }
    if (isInOnboarding) {
      returnString += getRerouteForAddAccountOnboarding();
    }
    return returnString;
  };

  const stateString = getStateString();

  return (
    `${baseURL}?${clientID}&response_type=code&${redirectURL}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2F${scopes}${loginHint}&prompt=${promptType}` +
    stateString
  );
}

export function isOutlookEventForwardable({ event }) {
  return isOutlookEvent(event) && getEventAttendees(event)?.length > 0;
}

export function isOutlookAllowNewTimeProposal(event) {
  return (
    event?.allow_new_time_proposals ||
    getEventRawData(event)?.allowNewTimeProposals
  );
}

export function getOutlookProposedEventTimes(event) {
  const getProposedTime = (attendees) => {
    // note: one strange thing is that propose  only works from @outlook.com to @weveapp.com and not vice versa.
    // could be the way that @weveapp.com account is set up
    const proposedTimes = attendees.filter((attendee) =>
      getOutlookAttendeeProposedTime(attendee)
    );
    return proposedTimes;
  };

  if (event?.attendees?.length > 0) {
    const { attendees } = event;
    const proposedTimes = getProposedTime(attendees);
    if (proposedTimes?.length > 0) {
      return proposedTimes;
    }
  }

  // this is a legacy raw_json check
  if (getEventRawData(event)?.attendees?.length > 0) {
    const { attendees } = getEventRawData(event);
    const proposedTimes = getProposedTime(attendees);
    if (proposedTimes?.length > 0) {
      return proposedTimes;
    }
  }

  return null;
}

function getOutlookAttendeeProposedTime(attendee) {
  return attendee?.proposedNewTime;
}

export function isOutlookEventAndOrganizer({ event, allCalendars }) {
  if (!isOutlookEvent(event)) {
    return false;
  }
  const matchingCalendar = getCalendarFromUserCalendarID({
    userCalendarID: getEventUserCalendarID(event),
    allCalendars,
  });
  const matchingCalendarEmail = getCalendarEmail(matchingCalendar);
  return isUserEmailOrganizer({ event, eventEmail: matchingCalendarEmail });
}

export function getOutlookCalendarAccessRole(calendar) {
  return getCalendarObject(calendar)?.access_role;
}

export function isOutlookCalendarOwnerAccessRole(calendar) {
  return getOutlookCalendarAccessRole(calendar) === "owner";
}

export function isOutlookCalendarEditorAccessRole(calendar) {
  return getOutlookCalendarAccessRole(calendar) === "writer";
}

export function isOutlookCalendarDeletable(calendar) {
  return getCalendarObject(calendar)?.is_deletable;
}

export function shouldGateExtendedProperties(calendar) {
  return (
    isCalendarOutlookCalendar(calendar) &&
    !(
      isOutlookCalendarOwnerAccessRole(calendar) ||
      isOutlookCalendarEditorAccessRole(calendar)
    )
  );
}

export function shouldUseTagColorAsEventColorForOutlookEvent({
  event,
  user,
  currentUser,
  allLoggedInUsers,
}) {
  if (!isOutlookEvent(event)) {
    return false;
  }
  const allTags = getAllTagsFromEvent({
    event,
    user,
    currentUser,
    allLoggedInUsers,
  });
  if (isEmptyArray(allTags)) {
    return false;
  }

  const firstTag = allTags[0];
  if (isTransparentTag(firstTag)) {
    return false;
  }

  const shouldConsiderCategories =
    isSyncingCategories(user) || isSyncingCategories(currentUser);
  if (
    !isPriorityTag(firstTag) &&
    getEventCategories(event)?.length > 0 &&
    shouldConsiderCategories
  ) {
    return false;
  }

  return !!firstTag?.color;
}

export function shouldHideEventResponseOptions(event) {
  // outlook does not show yes/no options if you're the organizer
  return isOutlookEvent(event) && isEventOrganizer(event);
}

export function formatAndHandleOutlookPreviewEvents({
  responses, // coupledResponse with {user, response}
  activeCalendarProviderIDs,
  currentTimeZone,
}) {
  if (
    isEmptyArrayOrFalsey(responses) ||
    isEmptyArrayOrFalsey(activeCalendarProviderIDs) ||
    !currentTimeZone
  ) {
    return;
  }
  const allFormattedEvents = [];
  responses.forEach((coupledResponse) => {
    const { response } = coupledResponse;
    const { events } = response;
    const formattedEvents = formatEventsArrayForReactBigCalendar({
      events,
      currentTimeZone,
      isPreviewOutlookEvent: true,
    });
    allFormattedEvents.push(...formattedEvents);
  });
  const filteredEvents = allFormattedEvents.filter((event) =>
    activeCalendarProviderIDs.includes(getEventCalendarProviderID(event))
  );
  const uniqueEvents = getUniquePreviewOutlookEvents(filteredEvents);
  mainCalendarBroadcast.publish(
    MAIN_CALENDAR_BROADCAST_VALUES.ADD_OUTLOOK_PREVIEW_EVENTS,
    {
      previewEvents: uniqueEvents,
    }
  );
}

export function stripeAllNewLines(text) {
  if (!text) {
    return "";
  }
  return text.replace(/(\r\n|\n|\r)/g, "");
}

// outlook adds the html to the hmtl and we want to add it to check for comparison
export function addDefaultOutlookDescriptionHTMLText(text) {
  return `<html>
<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
</head>
<body>
${text}
</body>
</html>`;
}

// outlook api has a bug where we can't rsvp to an event that has response_requested set to false
export function shouldDisplayOutlookResponseOptions(event) {
  const requestedResponse = getOutlookResponseRequested(event);
  if (requestedResponse === false) {
    // we treat null/false as true in this case
    return false;
  }
  return true;
}

export function isOutlookOOOEvent(event) {
  return isOutlookEvent(event) && isOutOfOfficeEvent(event);
}
